@import "utils/util.css";

.p-header {
    padding-top: 1rem;
}

.nav-logo:hover {
    background: transparent !important;
}

.p-navbar {
    height: 80px;
    background: #ffffffdd;
    border-bottom: 1px #eaeaea solid;
    z-index: 6;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 0 10px 0 #00000010;
}

.auto-grid {
    --auto-grid-min-size: 350px;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    grid-gap: 1.5rem;
    margin-bottom: 2rem;
}

.p-img-container-main {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(https://cdn.pixabay.com/photo/2017/05/19/06/22/desktop-2325627_960_720.jpg);
    width: 100%;
    height: 100%;
    background-size: cover;
}

.filebox label {
    display: inline-block;
    padding: .5em .75em;
    color: black;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    background-color: #dddddd;
    cursor: pointer;
    border: 1px solid #ebebeb;
    border-bottom-color: #e2e2e2;
    border-radius: .25em;
}

.filebox input[type="file"] { /* 파일 필드 숨기기 */
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.ui.modal {
    border-radius: 20px !important;
}

.ui.modal>.icon:first-child+*, .ui.modal>:first-child:not(.icon) {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}
.ui.modal>:last-child {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.ui.dropdown .menu>.item>.image, .ui.dropdown .menu>.item>img, .ui.dropdown>.text>.image, .ui.dropdown>.text>img {
    margin-left: 0.2em;
    border: 1px solid #eaeaea;
}

.ui.search.dropdown, .ui.selection.dropdown {
    border-radius: 10px !important;
}

.ui.selection.active.dropdown .menu {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

input {
    border-radius: 10px !important;
}

textarea {
    border-radius: 10px !important;
}

.ui.button {
    border-radius: 15px !important;
    padding: 1rem 1.5rem !important;
}

.tui-editor-defaultUI {
    border-radius: 10px !important;
    overflow: hidden;
}

.ui.labeled.input:not([class*="corner labeled"]) .label:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.ui.labeled.input:not([class*="corner labeled"]) .label:first-child+input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.toastui-editor-defaultUI {
    border-radius: 10px !important;
}

.toastui-editor-defaultUI .toastui-editor-md-tab-container {
    border-top-left-radius: 10px !important;
}
.toastui-editor-defaultUI-toolbar {
    border-radius: 3px 10px 0 0 !important;
}

.ProseMirror {
    height: 100% !important;
}